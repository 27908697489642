import { keyframes, styled } from "@mui/material"
import { Box } from "@mui/system"

type AlertProps = {
  type: "error" | "success" | ""
}

export const StyledAlert = styled(Box)<AlertProps>(({ type }) => ({
  fontWeight: 500,
  color: (() => {
    switch (type) {
      case "success":
        return "green"
      case "error":
        return "red"
    }
  })(),
  padding: "0px",
  marginLeft: "3rem",
  animation: `${fade} 500ms linear 0s 1 normal forwards, ${fade} 500ms linear 4s 1 reverse forwards`
}))

const fade = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

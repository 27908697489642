import { CreateElementDto, UserEntity } from "types"
import AddIcon from "@mui/icons-material/Add"
import { useState } from "react"
import { StyledFab } from "./style"
import CreateElementDialog from "./CreateElementDialog"
import { usePostElementMutation } from "app/api/bouwdataApi"
import { selectCurrentUser } from "app/redux/selectors/currentUserSelector"
import { useAppSelector } from "hooks/redux"

export default function CreateElement() {
  const [open, setOpen] = useState(false)
  const [postElement] = usePostElementMutation()

  const currentUser = useAppSelector(selectCurrentUser)
  const handleClose = () => setOpen(false)
  const handleOpen = () => setOpen(true)
  const isAllowed =
    currentUser?.userRole === UserEntity.userRole.ADMIN ||
    currentUser?.userRole === UserEntity.userRole.DATA_MINER
  const createElement = async (body: CreateElementDto) => {
    await postElement({ body })
  }

  return (
    <>
      {isAllowed && (
        <StyledFab
          data-testid="add-element-button"
          color="secondary"
          aria-label="add"
          onClick={handleOpen}
        >
          <AddIcon />
        </StyledFab>
      )}
      <CreateElementDialog
        createElement={createElement}
        open={open}
        onClose={handleClose}
      />
    </>
  )
}

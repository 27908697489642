import { RouteObject } from "./router"
import ClustersRoute from "./ClustersRoute"
import ClusterRoute from "./ClusterRoute"
import Layout from "app/components/Layout"
import { Navigate } from "react-router-dom"
import NotFound from "./NotFound"
import Expired from "./Expired"
import Callback from "./Callback"

const routes: RouteObject[] = [
  {
    component: () => <Navigate to="/clusters" />,
    path: "*"
  },
  {
    component: () => <Callback />,
    path: "/callback"
  },
  {
    component: ClustersRoute,
    layout: Layout,
    hasAuthentication: true,
    path: "/clusters"
  },

  {
    component: ClusterRoute,
    layout: Layout,
    hasAuthentication: true,
    path: "/clusters/:id"
  },
  {
    component: Expired,
    layout: Layout,
    hasAuthentication: true,
    path: "/expired"
  },
  {
    component: NotFound,
    path: "/notfound"
  }
]

export default routes

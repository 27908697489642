import { useTranslate } from "hooks/translate"
import { useEffect } from "react"
import { StyledAlert } from "./style"

type AlertData = {
  message: string
  type: "error" | "success" | ""
  isVisible: boolean
}

type AlertProps = {
  data: AlertData
  setData: React.Dispatch<React.SetStateAction<AlertData>>
}

const Alert = ({ data, setData }: AlertProps) => {
  const { translate } = useTranslate()
  useEffect(() => {
    setTimeout(() => {
      setData({ message: "", type: "", isVisible: false })
    }, 5000)
  }, [data])
  return <StyledAlert type={data.type}>{translate(data.message)}</StyledAlert>
}

export default Alert

import { Typography } from "@mui/material"
import { ClusterEntity } from "types"
import { Link } from "react-router-dom"
import { StyledBox, StyledTreeItem } from "./style"
import { useTranslate } from "hooks/translate"
import { useAppDispatch } from "hooks/redux"
import { setExpandedTreeNodes } from "app/redux/slices/ClustersOverviewSlice"
import { Variant } from "@mui/material/styles/createTypography"

type ClusterTreeItemProps = {
  label: string
  clusterType: ClusterEntity.clusterType
  clusterId: string
  children?: any
  depth: number
}

const depthVariants: { [key: number]: Variant } = {
  1: "h5",
  2: "h6",
  3: "body1"
}

const ClusterTreeItem = ({
  label,
  clusterType,
  clusterId,
  children,
  depth
}: ClusterTreeItemProps) => {
  const dispatch = useAppDispatch()
  const { translate } = useTranslate()
  const onClick = () => {
    if (children.length > 0) dispatch(setExpandedTreeNodes(clusterId))
  }

  return (
    <StyledTreeItem
      depth={depth}
      key={clusterId}
      nodeId={clusterId}
      onClick={onClick}
      label={
        <StyledBox>
          <Typography variant={depthVariants[depth]}>{label}</Typography>
          {clusterType === ClusterEntity.clusterType.ELEMENT_CLUSTER && (
            <Link to={`/clusters/${clusterId}`}>
              <Typography variant="body2">
                {translate("details.page")}
              </Typography>
            </Link>
          )}
        </StyledBox>
      }
    >
      {children}
    </StyledTreeItem>
  )
}

export default ClusterTreeItem

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UpdateUserDto = {
    firstName?: string;
    lastName?: string;
    email?: string;
    language?: UpdateUserDto.language;
    userRole?: UpdateUserDto.userRole;
    expiresAt?: string;
};

export namespace UpdateUserDto {

    export enum language {
        ADMIN = 'ADMIN',
        DATA_MINER = 'DATA_MINER',
        ESTIMATOR = 'ESTIMATOR',
    }

    export enum userRole {
        ADMIN = 'ADMIN',
        DATA_MINER = 'DATA_MINER',
        ESTIMATOR = 'ESTIMATOR',
    }


}

import { GridCellEditCommitParams, GridSelectionModel } from "@mui/x-data-grid"
import {
  useDeleteElementMutation,
  usePatchElementMutation
} from "app/api/bouwdataApi"
import { setVisibleElementIds } from "app/redux/slices/clusterDetailSlice"
import {
  selectClusterDetail,
  selectCurrentCluster
} from "app/redux/selectors/clusterDetailSelectors"
import { useDispatch, useSelector } from "react-redux"
import { ElementEntity } from "types"
import GenericDataGrid from "../GenericDataGrid"
import { useElementsColumns } from "./hooks"

type Props = {
  elements: ElementEntity[]
}
export default function ElementsGrid({ elements }: Props) {
  const dispatch = useDispatch()
  const [patchElement, { isLoading: isUpdating }] = usePatchElementMutation()
  const [, { isLoading: isDeleting }] = useDeleteElementMutation()
  const { visibleElementIds } = useSelector(selectClusterDetail)
  const currentCluster = useSelector(selectCurrentCluster)
  const { columns } = useElementsColumns()
  if (!currentCluster) return <></>

  const onCellEditCommit = async (params: GridCellEditCommitParams) => {
    const id = params.id as string
    const field = params.field as keyof ElementEntity
    const value = params.value as ElementEntity[keyof ElementEntity]

    const element = elements.find(element => element.id === id)
    if (!element) return

    const hasChanged = element[field] !== value
    if (!hasChanged) return

    await patchElement({ id, body: { [field]: value } })
  }

  const onSelectionModelChange = (ids: GridSelectionModel) => {
    dispatch(setVisibleElementIds(ids as ElementEntity["id"][]))
  }

  return (
    <GenericDataGrid<ElementEntity>
      isLoading={isUpdating || isDeleting}
      rows={elements}
      columns={columns}
      onCellEditCommit={onCellEditCommit}
      onSelectionModelChange={onSelectionModelChange}
      selectionModel={visibleElementIds}
    />
  )
}

import { LinearProgress } from "@mui/material"
import { useGetCurrentUserQuery } from "app/api/bouwdataApi"
import { useNavigate } from "react-router-dom"

type Props = {
  children: JSX.Element
}
const WithCurrentUser = ({ children }: Props) => {
  const { data: currentUser, isLoading } = useGetCurrentUserQuery()
  const navigate = useNavigate()
  if (isLoading) return <LinearProgress />
  if (!currentUser) navigate("/notfound")
  if (
    currentUser?.expiresAt &&
    new Date(currentUser?.expiresAt) < new Date()
  ) {
    navigate("/expired")
  }

  return <>{children}</>
}

export default WithCurrentUser

import {
  Button,
  Dialog as MuiDialog,
  DialogActions,
  DialogContent,
  Grid,
  DialogProps
} from "@mui/material"
import EnhancedDialogTitle from "./EnhancedDialogTitle"

type TDialogProps = {
  open: boolean
  children: JSX.Element
  onClose: () => void
  title: string
  actions: {
    variant: string
    label: string
    type?: string
    onclick?: () => void
    formId?: string
  }[]
} & DialogProps

const Dialog = ({
  open,
  children,
  maxWidth = "xs",
  onClose,
  title,
  actions
}: TDialogProps) => {
  return (
    <MuiDialog maxWidth={maxWidth} open={open} onClose={onClose}>
      <EnhancedDialogTitle onClose={onClose}>{title}</EnhancedDialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Grid container spacing={1} justifyContent="flex-end">
          {actions.map(({ formId, label, onClick, variant, type }: any) => (
            <Grid item key={label}>
              <Button
                form={formId}
                variant={variant}
                onClick={onClick}
                type={type}
              >
                {label}
              </Button>
            </Grid>
          ))}
        </Grid>
      </DialogActions>
    </MuiDialog>
  )
}

export default Dialog

import {
  DataGrid,
  GridCallbackDetails,
  GridColDef,
  GridEventListener,
  GridEvents,
  GridInputSelectionModel,
  GridSelectionModel,
} from "@mui/x-data-grid"
import LoadingOverlay from "./LoadingOverlay"
import NoRowsOverlay from "./NoRowsOverlay"

type Props<Entity> = {
  rows: Entity[]
  columns: GridColDef[]
  onCellEditCommit: GridEventListener<GridEvents.cellEditCommit>
  onSelectionModelChange?: (selectionModel: GridSelectionModel, details: GridCallbackDetails) => void
  selectionModel?: GridInputSelectionModel 
  isLoading: boolean
}
export default function GenericDataGrid<Entity extends { id: string }>({
  rows,
  columns,
  onCellEditCommit,
  onSelectionModelChange,
  selectionModel,
  isLoading,
}: Props<Entity>) {
  return (
    <DataGrid
      components={{
        LoadingOverlay: LoadingOverlay,
        NoRowsOverlay: NoRowsOverlay
      }}
      hideFooterPagination={true}
      disableColumnMenu={true}
      disableColumnFilter={true}
      rows={rows}
      columns={columns}
      checkboxSelection
      disableSelectionOnClick
      loading={isLoading}
      onCellEditCommit={onCellEditCommit}
      onSelectionModelChange={onSelectionModelChange}
      selectionModel={selectionModel}
    />
  )
}

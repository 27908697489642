import { useAppDispatch, useAppSelector } from "hooks/redux"
import { Grid, InputAdornment, Typography } from "@mui/material"
import Alert from "app/components/Alert"
import IconCrossmarkButton from "app/components/IconCrossmarkButton"
import { selectCurrentCluster } from "app/selectors/clusterDetailSelectors"
import { useTranslate } from "hooks/translate"
import { useEffect, useState } from "react"
import {
  StyledTextField,
  StyledButton,
  StyledEstimatedField,
  StyledTypography
} from "./style"
import { selectGraphSpecs } from "app/redux/selectors/graphSpecs"
import { setEstimatedValue, setGraphDomain } from "app/redux/slices/graphSlice"
import { determineNewDomain } from "./utils"
import { RegressionTypes } from "../Graph/types"
import { selectRegression } from "app/redux/selectors/reggressionSelector"

const CostEstimationFields = () => {
  const dispatch = useAppDispatch()
  const { translate } = useTranslate()
  const currentCluster = useAppSelector(selectCurrentCluster)
  const { estimatedValues, defaultDomainValues } =
    useAppSelector(selectGraphSpecs)
  const { type: regressionType, result: regressionResult } =
    useAppSelector(selectRegression)
  const [quantity, setQuantity] = useState<number | "">("")
  const [alertData, setAlertData] = useState<{
    message: string
    type: "success" | "error" | ""
    isVisible: boolean
  }>({ message: "", type: "", isVisible: false })

  useEffect(() => {
    if (estimatedValues.showEstimation) {
      handleCalculateKKG()
    }
  }, [regressionResult])

  const onChange = (e: React.ChangeEvent<any>) => {
    const newQuantity = Number(e.target.value)
    setQuantity(newQuantity)
  }

  const handleDispatch = ({
    quantity,
    currentCost
  }: {
    quantity: number
    currentCost: number
  }) => {
    const newDomain = determineNewDomain({
      quantity,
      currentCost,
      defaultDomain: defaultDomainValues
    })

    dispatch(
      setEstimatedValue({
        quantity,
        currentCost,
        showEstimation: true
      })
    )
    dispatch(setGraphDomain(newDomain))
  }

  const handleCalculateKKG = () => {
    if (!quantity) return
    if (regressionType === RegressionTypes.POINT || regressionResult === null) {
      setAlertData({
        message: "alert.message.min",
        type: "error",
        isVisible: true
      })
      return
    }
    if (regressionType === RegressionTypes.LINEAR) {
      const newKKG = regressionResult[0] * quantity + regressionResult[1]
      if (newKKG < 0) {
        handleDispatch({
          quantity: -(regressionResult[1] / regressionResult[0]),
          currentCost: 0
        })
        setAlertData({
          message: "alert.message.success",
          type: "success",
          isVisible: true
        })
        return
      }
      handleDispatch({
        quantity: Number(quantity.toFixed(0)),
        currentCost: Number(newKKG.toFixed(0))
      })
      return
    }
    const calculatedValues = [
      regressionResult[0] * Math.pow(quantity, 2),
      regressionResult[1] * quantity,
      regressionResult[2]
    ]
    const newKKG =
      calculatedValues[0] + calculatedValues[1] + calculatedValues[2]

    if (newKKG < 0) {
      handleDispatch({ quantity: Number(quantity.toFixed(0)), currentCost: 0 })
      setAlertData({
        message: "alert.message.success",
        type: "success",
        isVisible: true
      })
      return
    }
    handleDispatch({
      quantity: Number(quantity.toFixed(0)),
      currentCost: Number(newKKG.toFixed(0))
    })
    setAlertData({
      message: "alert.message.success",
      type: "success",
      isVisible: true
    })
    return
  }

  const handleClick = () => {
    handleCalculateKKG()
  }

  const handleClearInput = () => {
    setQuantity("")
    dispatch(
      setEstimatedValue({
        quantity: "",
        currentCost: "",
        showEstimation: false
      })
    )
  }

  const estimatedCost =
    estimatedValues.currentCost === ""
      ? ""
      : estimatedValues.currentCost.toFixed(0)

  if (!currentCluster) return <></>
  return (
    <Grid container mb={2} spacing={1}>
      <Grid container item>
        <Grid item>
          <StyledTextField
            size="small"
            id="unit"
            name="unit"
            label={currentCluster.meta.unit}
            value={quantity}
            onChange={onChange}
            InputProps={
              quantity !== ""
                ? {
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconCrossmarkButton
                          onClick={() => handleClearInput()}
                        />
                      </InputAdornment>
                    )
                  }
                : undefined
            }
          />
        </Grid>
        <Grid item>
          <StyledButton
            disabled={regressionResult === null}
            variant="contained"
            color="secondary"
            onClick={handleClick}
          >
            {translate("calculate")}
          </StyledButton>
        </Grid>
      </Grid>
      <Grid container item>
        <Typography variant="body2">{translate("estimated.kkg")}:</Typography>
      </Grid>
      <Grid container item>
        <Grid container item sx={{ position: "relative" }}>
          <StyledEstimatedField
            variant="standard"
            focused={true}
            disabled
            size="small"
            id="unit"
            name="unit"
            value={estimatedCost}
            onChange={onChange}
          />
          <StyledTypography variant="body1">
            €/{currentCluster.meta.unit}
          </StyledTypography>
          {alertData.isVisible && (
            <Alert setData={setAlertData} data={alertData} />
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default CostEstimationFields

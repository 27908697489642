/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ClusterMetaGraphEntity = {
    xField: ClusterMetaGraphEntity.xField;
    yField: ClusterMetaGraphEntity.yField;
};

export namespace ClusterMetaGraphEntity {

    export enum xField {
        QUANTITY = 'quantity',
        PRICE = 'price',
        CURRENT_COST = 'currentCost',
        ORIGINAL_COST = 'originalCost',
    }

    export enum yField {
        QUANTITY = 'quantity',
        PRICE = 'price',
        CURRENT_COST = 'currentCost',
        ORIGINAL_COST = 'originalCost',
    }


}

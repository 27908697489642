import { configureStore } from "@reduxjs/toolkit"
import { setupListeners } from "@reduxjs/toolkit/dist/query"
import { bouwdataApi } from "app/api/bouwdataApi"
import { errorLogger } from "./middlewares/errorLogger"
import clusterDetailReducer from "app/redux/slices/clusterDetailSlice"
import clustersOverviewReducer from "app/redux/slices/ClustersOverviewSlice"
import graphSpecsReducer from "app/redux/slices/graphSlice"
import regressionReducer from "app/redux/slices/regressionSlice"

export const store = configureStore({
  reducer: {
    clusterDetail: clusterDetailReducer,
    graphSpecs: graphSpecsReducer,
    clustersOverview: clustersOverviewReducer,
    regression: regressionReducer,
    [bouwdataApi.reducerPath]: bouwdataApi.reducer
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(bouwdataApi.middleware).concat(errorLogger)
})

setupListeners(store.dispatch)

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

import { ClusterMetaGraphEntity, ElementEntity } from "types"
import { RegressionLineValues, RegressionTypes } from "./types"

const amountOfPoints = 10

export const calculateRegressionLine = ({
  regressionType,
  regressionResult,
  visibleElements,
  maxXValue,
  xField,
  yField
}: RegressionLineValues) => {
  if (regressionType === RegressionTypes.POINT || regressionResult === null) {
    return
  }

  const xValues = calculateXValues(visibleElements, maxXValue, xField)
  if (regressionType === RegressionTypes.LINEAR) {
    return xValues.map(xValue => {
      const yValue = regressionResult[0] * xValue + regressionResult[1]
      //calculate x value when y is lower than 0 so the line does not go into negative
      if (yValue < 0) {
        return {
          [xField]: -(regressionResult[1] / regressionResult[0]),
          [yField]: 0
        }
      }
      return {
        [xField]: xValue,
        [yField]: yValue
      }
    })
  }
  return xValues.map(xValue => {
    const yValue = calculateYValueBasedOnEquation(xValue, regressionResult)
    return {
      [xField]: xValue,
      [yField]: yValue
    }
  })
}

export const calculateXValues = (
  visibleElements: ElementEntity[],
  maxXValue: number,
  xField: ClusterMetaGraphEntity.xField
) => {
  const xValues = visibleElements.map((element: ElementEntity) =>
    Number(element[xField])
  )
  const max = Math.max.apply(Math, xValues)
  const min = Math.min.apply(Math, xValues)
  const interval = (max - min) / amountOfPoints
  const intervalArray = Array.from({ length: amountOfPoints }, (_, i) => i + 1)
  const linePoints = [
    0,
    min,
    ...intervalArray.map(i => Number((min + interval * i).toFixed(2))),
    maxXValue
  ]
  return linePoints
}

const calculateYValueBasedOnEquation = (
  testNumber: number,
  equation: number[]
) => {
  const calculatedValues = [
    Number((equation[0] * Math.pow(testNumber, 2)).toFixed(2)),
    equation[1] * testNumber,
    equation[2]
  ]
  const yValue = calculatedValues[0] + calculatedValues[1] + calculatedValues[2]

  return yValue > 0 ? yValue : 0
}

export const getMaxXValue = (
  elements: ElementEntity[],
  xField: ClusterMetaGraphEntity.xField
) => {
  const xValues = elements.map((element: ElementEntity) =>
    Number(element[xField])
  )
  return Math.max.apply(Math, xValues)
}

import { ClusterEntity, ElementEntity } from "types"
import { clusterEndpoints } from "."

type AddElementProps = {
  id: ClusterEntity["id"]
  newElement: ElementEntity
}
export const addElement = ({ id, newElement }: AddElementProps) => {
  return clusterEndpoints.util.updateQueryData(
    "getCluster",
    id,
    (cluster: ClusterEntity) => ({
      ...cluster,
      elements: [...cluster.elements, newElement]
    })
  )
}

type RemoveElementProps = {
  id: ClusterEntity["id"]
  elementId: ElementEntity["id"]
}
export const removeElement = ({ id, elementId }: RemoveElementProps) => {
  return clusterEndpoints.util.updateQueryData(
    "getCluster",
    id,
    (cluster: ClusterEntity) => ({
      ...cluster,
      elements: cluster.elements.filter(element => element.id !== elementId)
    })
  )
}

type ReplaceElementProps = {
  id: ClusterEntity["id"]
  updatedElement: ElementEntity
}
export const replaceElement = ({ id, updatedElement }: ReplaceElementProps) => {
  return clusterEndpoints.util.updateQueryData(
    "getCluster",
    id,
    (cluster: ClusterEntity) => ({
      ...cluster,
      elements: cluster.elements.map(element => {
        if (element.id !== updatedElement.id) return element
        return updatedElement
      })
    })
  )
}

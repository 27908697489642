import {
  ComposedChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Scatter,
  ResponsiveContainer
} from "recharts"
import { StyledBox } from "./style"
import {
  selectHiddenElements,
  selectVisibleElements
} from "app/redux/selectors/clusterDetailSelectors"
import { useAppSelector } from "hooks/redux"
import regression, { DataPoint } from "regression"
import { ElementEntity } from "types"
import { calculateRegressionLine, getMaxXValue } from "./utils"
import { RegressionTypes } from "./types"
import { selectCurrentCluster } from "app/redux/selectors/clusterDetailSelectors"
import { selectGraphSpecs } from "app/redux/selectors/graphSpecs"
import YAxisLabel from "./YAxisLabel"
import { useTranslate } from "hooks/translate"
import { useEffect } from "react"
import { useAppDispatch } from "hooks/redux"
import {
  setDefaultGraphDomain,
  setGraphDomain
} from "app/redux/slices/graphSlice"
import { setRegression } from "app/redux/slices/regressionSlice"
import { selectRegression } from "app/redux/selectors/reggressionSelector"

const Graph = () => {
  const dispatch = useAppDispatch()
  const { translate } = useTranslate()
  const currentCluster = useAppSelector(selectCurrentCluster)
  const hiddenElements = useAppSelector(selectHiddenElements)
  const { domainValues, estimatedValues } = useAppSelector(selectGraphSpecs)
  const visibleElements = useAppSelector(selectVisibleElements)
  const { type: regressionType, result: regressionResult } =
    useAppSelector(selectRegression)
  const {
    estimatedValues: { showEstimation }
  } = useAppSelector(selectGraphSpecs)
  const calculateRegression = (dataPoints: DataPoint[]) => {
    if (dataPoints.length <= 1) {
      dispatch(
        setRegression({
          result: null,
          type: RegressionTypes.POINT
        })
      )
      return
    }
    if (dataPoints.length === 2) {
      const { equation } = regression.linear(dataPoints, { precision: 5 })
      dispatch(
        setRegression({
          result: equation,
          type: RegressionTypes.LINEAR
        })
      )
      return
    }
    const { equation } = regression.polynomial(dataPoints, {
      precision: 20
    })
    dispatch(
      setRegression({
        result: equation,
        type: RegressionTypes.POLYNOMIAL
      })
    )
    return
  }
  useEffect(() => {
    if (!currentCluster) return
    const maxYValue = Math.max.apply(
      Math,
      currentCluster.elements.map((element: ElementEntity) =>
        Number(element[yField])
      )
    )
    const maxXValue = getMaxXValue(
      [...hiddenElements, ...visibleElements],
      xField
    )
    dispatch(
      setDefaultGraphDomain({
        x: maxXValue,
        y: maxYValue
      })
    )
    dispatch(
      setGraphDomain({
        x: maxXValue,
        y: maxYValue
      })
    )
  }, [currentCluster])

  useEffect(() => {
    //datapoints = [x,y]
    const dataPoints = visibleElements.map((e: ElementEntity) => {
      return [e[xField], Number(e[yField])] as DataPoint
    })
    calculateRegression(dataPoints)
  }, [visibleElements.length])
  if (!currentCluster) return <div data-testid="graph" />
  const xField = currentCluster.meta.graph.xField
  const yField = currentCluster.meta.graph.yField

  const calculatedLine = calculateRegressionLine({
    visibleElements,
    regressionType,
    regressionResult,
    maxXValue: domainValues.x,
    xField,
    yField
  })
  const drawLine =
    regressionType === RegressionTypes.POLYNOMIAL ||
    regressionType === RegressionTypes.LINEAR
  return (
    <StyledBox data-testid="graph">
      <ResponsiveContainer aspect={3}>
        <ComposedChart
          margin={{
            top: 20,
            right: 80,
            bottom: 30,
            left: 20
          }}
          data={calculatedLine}
        >
          <CartesianGrid stroke="#f5f5f5" />
          <XAxis
            dataKey={xField}
            type="number"
            domain={[0, domainValues.x]}
            label={{
              value: translate(`elements.${xField}`, currentCluster!.meta),
              position: "insideBottomRight",
              offset: -16
            }}
          />
          <YAxis
            dataKey={yField}
            type="number"
            domain={[0, domainValues.y]}
            label={
              <YAxisLabel
                value={translate(`elements.${yField}`, currentCluster!.meta)}
              />
            }
          />
          {drawLine && (
            <Line
              name={yField}
              type="monotone"
              dataKey={yField}
              stroke="blue"
              dot={false}
              activeDot={false}
              legendType="none"
            />
          )}
          <Scatter
            name={yField}
            isAnimationActive={false}
            data={visibleElements}
            fill="#2B2C7E"
          />
          {
            //if hiddenElements is empty the chart will render a scatter of all calculated x values for the line
            hiddenElements.length > 0 && (
              <Scatter
                name={yField}
                isAnimationActive={false}
                data={hiddenElements}
                fill="#D6D6D6"
              />
            )
          }
          {showEstimation && (
            <Scatter
              name={yField}
              isAnimationActive={false}
              data={[estimatedValues]}
              fill="#f50057"
            />
          )}
        </ComposedChart>
      </ResponsiveContainer>
    </StyledBox>
  )
}
export default Graph

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { sec } from "app/utils/security"
import { API_URLS } from "../config"

export const bouwdataApi = createApi({
  reducerPath: "bouwdataApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_URLS.bouwdata,
    prepareHeaders: async headers => {
      const getAccessTokenSilently = sec.getAccessTokenSilently()
      if (!getAccessTokenSilently) return headers

      const token = await getAccessTokenSilently()
      if (token) headers.set("authorization", `Bearer ${token}`)
      return headers
    }
  }),
  endpoints: _builder => ({})
})

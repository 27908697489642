import { LinearProgress } from "@mui/material"
import { GridOverlay } from "@mui/x-data-grid"
import { StyledDiv } from "./style"

export default function LoadingOverlay() {
  return (
    <GridOverlay>
      <StyledDiv>
        <LinearProgress />
      </StyledDiv>
    </GridOverlay>
  )
}

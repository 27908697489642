import Router from "./routes/router"
import { BrowserRouter } from "react-router-dom"
import { store } from "./app/redux/store"
import { Provider } from "react-redux"
import I18nProvider from "providers/I18nProvider"
import { CssBaseline } from "@mui/material"
import { ThemeProvider } from "@mui/system"
import { SnackbarProvider } from "notistack"
import theme from "theme"
import { SnackbarUtilsConfigurator } from "app/utils/SnackBarUtils"
import { Auth0ProviderWithHistory } from "providers/Auth0ProviderWithHistory"

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <I18nProvider>
          <BrowserRouter>
            <SnackbarProvider maxSnack={3}>
              <SnackbarUtilsConfigurator />
              <Auth0ProviderWithHistory>
                <Router />
              </Auth0ProviderWithHistory>
            </SnackbarProvider>
          </BrowserRouter>
        </I18nProvider>
      </ThemeProvider>
    </Provider>
  )
}

export default App

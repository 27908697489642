import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { ClusterEntity } from "types"

type ClustersOverviewState = {
  expandedTreeNodes: ClusterEntity["id"][]
}

const initialState: ClustersOverviewState = {
  expandedTreeNodes: []
}

export const clusterOverviewSlice = createSlice({
  name: "clustersOverview",
  initialState,
  reducers: {
    setExpandedTreeNodes: (
      state,
      actions: PayloadAction<ClusterEntity["id"]>
    ) => {
      const alreadyIncluded = state.expandedTreeNodes.includes(actions.payload)
      const expandedTreeNodes = alreadyIncluded
        ? state.expandedTreeNodes.filter(id => id !== actions.payload)
        : [...state.expandedTreeNodes, actions.payload]
      return {
        ...state,
        expandedTreeNodes
      }
    }
  }
})

export const { setExpandedTreeNodes } = clusterOverviewSlice.actions
export default clusterOverviewSlice.reducer

import { createTheme } from "@mui/material/styles"

const theme = createTheme({
  typography: {
    fontFamily: "Verdana"
  },
  palette: {
    mode: "light",
    primary: {
      main: "#000080"
    },
    secondary: {
      main: "#f50057"
    }
  }
})

export default theme

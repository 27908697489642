import { CreateElementDto, ElementEntity, UpdateElementDto } from "types"
import { bouwdataApi } from "../base"
import { addElement, removeElement, replaceElement } from "../clusterEndpoints/actions"

type PostProps = { body: CreateElementDto }
type PatchProps = { id: ElementEntity["id"]; body: UpdateElementDto }

export const elementEnpoints = bouwdataApi.injectEndpoints({
  endpoints: builder => ({
    postElement: builder.mutation<ElementEntity, PostProps>({
      query: ({ body }) => ({
        url: "elements",
        method: "POST",
        body
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data: newElement } = await queryFulfilled
          const id = newElement.clusterId
          dispatch(addElement({ id, newElement }))
        } catch {}
      }
    }),

    patchElement: builder.mutation<ElementEntity, PatchProps>({
      query: ({ id, body }) => ({
        url: `elements/${id}`,
        method: "PATCH",
        body
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedElement } = await queryFulfilled
          const id = updatedElement.clusterId
          dispatch(replaceElement({ id, updatedElement }))
        } catch {}
      }
    }),

    deleteElement: builder.mutation<ElementEntity, ElementEntity["id"]>({
      query: id => ({
        url: `elements/${id}`,
        method: "DELETE"
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data: deletedElement } = await queryFulfilled
          const id = deletedElement.clusterId
          const elementId = deletedElement.id
          dispatch(removeElement({ id, elementId }))
        } catch {}
      }
    })
  })
})

export const {
  useDeleteElementMutation,
  usePatchElementMutation,
  usePostElementMutation
} = elementEnpoints

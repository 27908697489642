import {
  styled,
  Grid,
  TextField,
  Button,
  Typography,
  Alert
} from "@mui/material"

export const StyledGrid = styled(Grid)(() => ({
  marginBottom: "20px"
}))

export const StyledTextField = styled(TextField)(() => ({
  marginRight: "8px"
}))

export const StyledButton = styled(Button)(() => ({
  boxShadow: "none",
  "&:hover": {
    boxShadow: "none"
  }
}))
export const StyledEstimatedField = styled(TextField)(() => ({
  width: "3rem",
  marginRight: "1rem",
  ".MuiInput-input": {
    textAlign: "right"
  },
  ".MuiInputBase-input.Mui-disabled": {
    WebkitTextFillColor: "black",
    color: "black"
  },
  "& .MuiInput-root.Mui-disabled:before": {
    borderBottomStyle: "solid"
  },
  "& .MuiInput-underline:before": {
    borderBottomColor: "black"
  }
}))

export const StyledAlert = styled(Alert)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  marginLeft: "2rem",
  fontWeight: 500,
  padding: "0px"
}))

export const StyledTypography = styled(Typography)(() => ({
  fontWeight: 600
}))

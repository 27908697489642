/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ClusterMetaGridEntity = {
    field: ClusterMetaGridEntity.field;
    type: string;
    formatter: ClusterMetaGridEntity.formatter;
};

export namespace ClusterMetaGridEntity {

    export enum field {
        LOCATION = 'location',
        EXPLANATION = 'explanation',
        REFERENCE_DATE = 'referenceDate',
        QUANTITY = 'quantity',
        PRICE = 'price',
        CURRENT_COST = 'currentCost',
        ORIGINAL_COST = 'originalCost',
    }

    export enum formatter {
        DEFAULT = 'default',
        PERCENTAGE = 'percentage',
        PRICE = 'price',
    }


}

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CreateUserDto = {
    firstName: string;
    lastName: string;
    email: string;
    userRole: CreateUserDto.userRole;
    language: CreateUserDto.language;
    expiresAt?: string;
};

export namespace CreateUserDto {

    export enum userRole {
        ADMIN = 'ADMIN',
        DATA_MINER = 'DATA_MINER',
        ESTIMATOR = 'ESTIMATOR',
    }

    export enum language {
        NL = 'NL',
        FR = 'FR',
        EN = 'EN',
    }


}

import { DialogTitle, DialogTitleProps } from "@mui/material"
import { StyledCloseButton } from "./style"
import CloseIcon from "@mui/icons-material/Close"

type TDialogTitle = {
  children: string
  onClose: () => void
} & DialogTitleProps

const EnhancedDialogTitle = ({ onClose, children }: TDialogTitle) => {
  return (
    <DialogTitle>
      {children}
      {onClose ? (
        <StyledCloseButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </StyledCloseButton>
      ) : null}
    </DialogTitle>
  )
}

export default EnhancedDialogTitle

import {
  MiddlewareAPI,
  isRejectedWithValue,
  Middleware,
} from '@reduxjs/toolkit'
import SnackBarUtils from 'app/utils/SnackBarUtils'

export const errorLogger: Middleware =
  (_api: MiddlewareAPI) => (next) => (action) => {
    if (isRejectedWithValue(action)) {
      SnackBarUtils.error("something went wrong")
    }

    return next(action)
  }

import { Container } from "@mui/material"
import { Link } from "react-router-dom"

const NotFound = () => {
  return (
    <Container>
      <div>404 we did not find what you were looking for.</div>
      <Link to="/clusters">Home</Link>
    </Container>
  )
}

export default NotFound

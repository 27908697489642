import { createDraftSafeSelector } from "@reduxjs/toolkit"
import { RootState } from "app/redux/store"

const selectRootState = (state: RootState) => state

export const selectClustersOverview = createDraftSafeSelector(
  selectRootState,
  state => state.clustersOverview
)

export const selectExpandedTreeNodes = createDraftSafeSelector(
  selectClustersOverview,
  state => state.expandedTreeNodes
)

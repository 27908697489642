/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type AbexIndexEntity = {
    id: string;
    year: number;
    value: number;
    semester: AbexIndexEntity.semester;
    createdAt: string;
    updatedAt: string;
};

export namespace AbexIndexEntity {

    export enum semester {
        MAY = 'MAY',
        NOVEMBER = 'NOVEMBER',
    }


}

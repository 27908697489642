import { Button } from "@mui/material"
import { Link } from "react-router-dom"
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos"

type Props = {
  to: string
  text: string
}
export default function BackButton({ to, text }: Props) {
  return (
    <Button
      data-testid="backButton"
      component={Link}
      to={to}
      variant="text"
      startIcon={<ArrowBackIosIcon />}
    >
      {text}
    </Button>
  )
}

import LayoutAppBar from "./LayoutAppBar"

type Props = {
  children: JSX.Element
}

const Layout = ({ children }: Props): JSX.Element => {
  return (
    <main>
      <LayoutAppBar />
      {children}
    </main>
  )
}
export default Layout

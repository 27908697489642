import { createDraftSafeSelector } from "@reduxjs/toolkit"
import { userEndpoints } from "app/api/bouwdataApi"
import { RootState } from "app/redux/store"

const selectRootState = (state: RootState) => state

export const selectCurrentUser = createDraftSafeSelector(
  selectRootState,
  state => userEndpoints.endpoints.getCurrentUser.select()(state).data!
)

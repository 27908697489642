import { UserEntity } from "types/models/UserEntity"
import { bouwdataApi } from "../base"

export const userEndpoints = bouwdataApi.injectEndpoints({
  endpoints: builder => ({
    getUsers: builder.query<UserEntity[], void>({
      query: () => `users`
    }),
    getCurrentUser: builder.query<UserEntity, void>({
      query: () => `me`
    })
  })
})

export const { useGetUsersQuery, useGetCurrentUserQuery } = userEndpoints

import { useGetClusterQuery } from "app/api/bouwdataApi"
import Loading from "app/components/Loading"
import ClusterDetail from "app/containers/ClusterDetail"
import NotFound from "app/containers/NotFound"
import { useParams } from "react-router-dom"

export default function ClusterRoute() {
  const { id } = useParams()
  const { data: cluster, isLoading } = useGetClusterQuery(id || "")

  if (isLoading) return <Loading />
  if (cluster) return <ClusterDetail cluster={cluster} />

  return <NotFound />
}

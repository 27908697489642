import { styled, Toolbar } from "@mui/material"

export const StyledPageHeaderBar = styled(Toolbar)({
  justifyContent: "space-between"
})

type StyledPageHeaderNodeProps = { align: "left" | "center" | "right" }
export const StyledPageHeaderNode = styled("div")<StyledPageHeaderNodeProps>(
  ({ align }) => ({
    flex: 1,
    textAlign: align
  })
)

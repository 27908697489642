import { Box, styled, Typography } from "@mui/material"

export const Offset = styled("div")(({ theme }) => theme.mixins.toolbar)
export const Styledtypography = styled(Typography)({
  flexGrow: 1
})

export const StyledBox = styled(Box)({
  flexGrow: 1
})

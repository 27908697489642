import { Box, styled } from "@mui/material"
import { TreeItem } from "@mui/lab"

export const StyledBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between"
}))

export const StyledTreeItem = styled(TreeItem)<{ depth: number }>(
  ({ depth }) => {
    switch (depth) {
      case 1:
        return
      case 2:
        return
      case 3:
        return {
          marginLeft: "8px"
        }
    }
  }
)

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CreateAbexIndexDto = {
    year: number;
    value: number;
    semester: CreateAbexIndexDto.semester;
};

export namespace CreateAbexIndexDto {

    export enum semester {
        MAY = 'MAY',
        NOVEMBER = 'NOVEMBER',
    }


}

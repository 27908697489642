import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { ClusterEntity, ElementEntity } from "types"

type ClusterDetailState = {
  currentClusterId: string
  visibleElementIds: ElementEntity["id"][]
}

const initialState: ClusterDetailState = {
  currentClusterId: "",
  visibleElementIds: []
}
export const clusterDetailSlice = createSlice({
  name: "clusterDetail",
  initialState,
  reducers: {
    setCurrentClusterId: (
      state,
      actions: PayloadAction<ClusterEntity["id"]>
    ) => {
      return {
        ...state,
        currentClusterId: actions.payload
      }
    },
    setVisibleElementIds: (
      state,
      actions: PayloadAction<ElementEntity["id"][]>
    ) => {
      return { ...state, visibleElementIds: actions.payload }
    }
  }
})

export const { setCurrentClusterId, setVisibleElementIds } =
  clusterDetailSlice.actions
export default clusterDetailSlice.reducer

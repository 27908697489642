import DeleteIcon from "@mui/icons-material/Delete"
import { IconButton } from "@mui/material"

type Props = {
  onClick: () => void
}
export default function IconDeleteButton({ onClick }: Props) {
  return (
    <IconButton onClick={onClick}>
      <DeleteIcon />
    </IconButton>
  )
}

import { ClusterMetaGraphEntity, ElementEntity } from "types"

export enum RegressionTypes {
  NONE = "none",
  POINT = "point",
  LINEAR = "linear",
  POLYNOMIAL = "polynomial"
}

export type RegressionLineValues = {
  regressionType: RegressionTypes
  regressionResult: number[] | null
  visibleElements: ElementEntity[]
  maxXValue: number
  xField: ClusterMetaGraphEntity.xField
  yField: ClusterMetaGraphEntity.yField
}

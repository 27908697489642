/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UserEntity = {
    id: string;
    authzId?: any;
    firstName: string;
    lastName: string;
    email: string;
    userRole: UserEntity.userRole;
    language: UserEntity.language;
    expiresAt: string;
    createdAt: string;
    updatedAt: string;
};

export namespace UserEntity {

    export enum userRole {
        ADMIN = 'ADMIN',
        DATA_MINER = 'DATA_MINER',
        ESTIMATOR = 'ESTIMATOR',
    }

    export enum language {
        NL = 'NL',
        FR = 'FR',
        EN = 'EN',
    }


}

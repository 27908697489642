import { Container } from "@mui/material"
import { useTranslate } from "hooks/translate"

const Expired = () => {
  const { translate } = useTranslate()
  return (
    <Container>
      <div>{translate("user.expired")}</div>
    </Container>
  )
}

export default Expired

const YAxisLabel = ({ x, y, stroke, value }: any) => {
  return (
    <text
      x={x}
      y={y}
      dx={-150}
      dy={30}
      transform="rotate(270)"
      fill={stroke}
      textAnchor="middle"
    >
      {value}
    </text>
  )
}
export default YAxisLabel

import {
  GridCellParams,
  GridColDef,
  GridValueGetterParams
} from "@mui/x-data-grid"
import { useTranslate } from "hooks/translate"
import { ClusterEntity, ElementEntity, UserEntity } from "types"
import { Tooltip } from "@mui/material"
import { StyledDiv } from "./style"
import { getValueFormatter } from "./utils"
import IconDeleteButton from "../IconDeleteButton"
import { useDeleteElementMutation } from "app/api/bouwdataApi"
import { useSelector } from "react-redux"
import { selectCurrentUser } from "app/redux/selectors/currentUserSelector"
import { selectCurrentCluster } from "app/selectors/clusterDetailSelectors"

type RenderCellParams = GridValueGetterParams<GridCellParams, ElementEntity> & {
  formattedValue: string
}

export const useElementsColumns = () => {
  const { translate } = useTranslate()
  const [deleteElement] = useDeleteElementMutation()
  const currentUser = useSelector(selectCurrentUser)
  const currentCluster = useSelector(selectCurrentCluster)
  if (!currentCluster) return { columns: [] as GridColDef[] }

  const columns: GridColDef[] = [
    ...currentCluster.meta.grid.map(
      (column): GridColDef => ({
        type: column.type,
        field: column.field,
        headerName: translate(`elements.${column.field}`, currentCluster.meta),
        hide:
          currentUser.userRole === UserEntity.userRole.ESTIMATOR &&
          currentCluster.name === "b5" &&
          (column.field === "quantity" || column.field === "price"),
        flex: 1,
        sortable: false,
        editable: false,
        headerAlign: "left",
        renderCell: (params: RenderCellParams) => (
          <Tooltip placement={"bottom-start"} title={params.formattedValue}>
            {column.formatter === "price" ? (
              <StyledDiv>
                <span>€</span>
                <span>{params.formattedValue}</span>
              </StyledDiv>
            ) : (
              <span>{params.formattedValue}</span>
            )}
          </Tooltip>
        ),
        valueFormatter: params =>
          getValueFormatter({ type: column.formatter, params })
      })
    ),
    {
      hide: currentUser.userRole !== UserEntity.userRole.ADMIN,
      hideable: false,
      field: "actions",
      headerName: "",
      type: "number",
      sortable: false,
      filterable: false,
      flex: 1,
      renderCell: (params: RenderCellParams) => (
        <IconDeleteButton
          onClick={async () => await deleteElement(params.row.id)}
        />
      )
    }
  ]
  return { columns }
}

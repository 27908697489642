/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ClusterMetaEntity } from './ClusterMetaEntity';
import type { ElementEntity } from './ElementEntity';

export type ClusterTreeEntity = {
    id: string;
    parentId: any;
    name: string;
    meta: ClusterMetaEntity;
    elements: Array<ElementEntity>;
    clusterType: ClusterTreeEntity.clusterType;
    createdAt: string;
    updatedAt: string;
    children: Array<ClusterTreeEntity>;
};

export namespace ClusterTreeEntity {

    export enum clusterType {
        FOLDER_CLUSTER = 'FOLDER_CLUSTER',
        ELEMENT_CLUSTER = 'ELEMENT_CLUSTER',
    }


}

export const determineNewDomain = ({
  quantity,
  currentCost,
  defaultDomain
}: {
  quantity: number
  currentCost: number
  defaultDomain: {
    x: number
    y: number
  }
}) => {
  return (() => {
    const positiveX = quantity > 0 ? quantity : 0
    const positiveY = currentCost > 0 ? currentCost : 0
    if (quantity > defaultDomain.x && currentCost > defaultDomain.y) {
      return {
        x: positiveX,
        y: positiveY
      }
    } else if (quantity <= defaultDomain.x && currentCost > defaultDomain.y) {
      return {
        x: defaultDomain.x,
        y: positiveY
      }
    } else if (quantity > defaultDomain.x && currentCost <= defaultDomain.y) {
      return {
        x: positiveX,
        y: defaultDomain.y
      }
    }
    return {
      x: defaultDomain.x,
      y: defaultDomain.y
    }
  })()
}

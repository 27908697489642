import { useIntl } from "react-intl"

export const useTranslate = () => {
  const { formatMessage } = useIntl()

  const translate = (id: string, values?: {}): string =>
    formatMessage({ id }, values)

  return { translate }
}

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ClusterMetaEntity } from './ClusterMetaEntity';

export type UpdateClusterDto = {
    name?: string;
    meta?: ClusterMetaEntity;
    clusterType?: UpdateClusterDto.clusterType;
};

export namespace UpdateClusterDto {

    export enum clusterType {
        FOLDER_CLUSTER = 'FOLDER_CLUSTER',
        ELEMENT_CLUSTER = 'ELEMENT_CLUSTER',
    }


}

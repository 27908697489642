import enTranslations from "./en.translations.json"
import frTranslations from "./fr.translations.json"
import nlTranslations from "./nl.translations.json"

export enum Locales {
  NL = "NL",
  FR = "FR",
  EN = "EN"
}

const locales = [...Object.values(Locales)]

const translations = {
  [Locales.EN]: {
    ...enTranslations
  },
  [Locales.NL]: {
    ...nlTranslations
  },
  [Locales.FR]: {
    ...frTranslations
  }
}

const formats = {}

export { locales, translations, formats }

import { ClusterEntity, ClusterTreeEntity } from "types"
import { bouwdataApi } from "../base"

export const clusterEndpoints = bouwdataApi.injectEndpoints({
  endpoints: builder => ({
    getClustersTree: builder.query<ClusterTreeEntity[], void>({
      query: () => `clusters/tree`
    }),

    getCluster: builder.query<ClusterEntity, ClusterEntity["id"]>({
      query: id => `clusters/${id}`
    })
  })
})

export const { useGetClusterQuery, useGetClustersTreeQuery } = clusterEndpoints

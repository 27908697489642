import { GridValueFormatterParams } from "@mui/x-data-grid"

type GetValueFormatterParams = {
  type: "default" | "percentage" | "price" | null
  params: GridValueFormatterParams
}

export function getValueFormatter({ type, params }: GetValueFormatterParams) {
  if (type === "percentage") return percentageValueFormatter(params)
  if (type === "price") return priceValueFormatter(params)
  return defaultValueFormatter(params)
}

function defaultValueFormatter({ value }: GridValueFormatterParams) {
  return value
}

function percentageValueFormatter({ value }: GridValueFormatterParams) {
  if (value == null) return ""
  return `${value} %`
}

function priceValueFormatter({ value }: GridValueFormatterParams) {
  if (value == null) return ""
  const seperatedNumber = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
  return `${seperatedNumber}`
}

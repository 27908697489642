import { useGetClustersTreeQuery } from "app/api/bouwdataApi"
import Loading from "app/components/Loading"
import ClustersOverview from "app/containers/ClustersOverview"
import NotFound from "app/containers/NotFound"

export default function ClustersRoute() {
  const { data: clusters, isLoading } = useGetClustersTreeQuery()

  if (isLoading) return <Loading />
  if (clusters) return <ClustersOverview clusters={clusters} />

  return <NotFound />
}

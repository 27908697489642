import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RegressionTypes } from "app/components/Graph/types"

type regressionState = {
  result: number[] | null
  type: RegressionTypes
}

const initialState: regressionState = {
  result: null,
  type: RegressionTypes.NONE
}

export const regressionSlice = createSlice({
  name: "regression",
  initialState,
  reducers: {
    setRegression: (_, actions: PayloadAction<regressionState>) => {
      return actions.payload
    }
  }
})

export const { setRegression } = regressionSlice.actions
export default regressionSlice.reducer

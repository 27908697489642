import { IconButton } from "@mui/material"
import CancelIcon from "@mui/icons-material/Cancel"

type Props = {
  onClick: () => void
}
export default function IconCrossmarkButton({ onClick }: Props) {
  return (
    <IconButton onClick={onClick}>
      <CancelIcon />
    </IconButton>
  )
}

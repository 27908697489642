import { ReactNode } from "react"
import { StyledPageHeaderBar, StyledPageHeaderNode } from "./style"

type Props = {
  leftNode?: ReactNode
  centerNode?: ReactNode
  rightNode?: ReactNode
}
export default function PageHeader({ leftNode, centerNode, rightNode }: Props) {
  return (
    <StyledPageHeaderBar data-testid="pageHeader">
      <StyledPageHeaderNode align="left">{leftNode}</StyledPageHeaderNode>
      <StyledPageHeaderNode align="center">{centerNode}</StyledPageHeaderNode>
      <StyledPageHeaderNode align="right">{rightNode}</StyledPageHeaderNode>
    </StyledPageHeaderBar>
  )
}

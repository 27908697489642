import { Box, Grid, InputAdornment, TextField } from "@mui/material"
import Dialog from "app/components/Dialog"
import { selectCurrentCluster } from "app/redux/selectors/clusterDetailSelectors"
import { useFormik } from "formik"
import { useTranslate } from "hooks/translate"
import { useSelector } from "react-redux"
import { CreateElementDto } from "types"
import * as yup from "yup"

type CreateElementDialogProps = {
  open: boolean
  onClose: () => void
  createElement: (row: CreateElementDto) => Promise<void>
}

const CreateElementsSchema = yup.object({
  location: yup.string().required("Required"),
  referenceDate: yup.string().required("Required"),
  price: yup.number().min(0).required("Required"),
  quantity: yup.string().min(0).required("Required"),
  explanation: yup.string().max(420).required("Required")
})

const CreateElementDialog = ({
  open,
  onClose,
  createElement
}: CreateElementDialogProps) => {
  const { translate } = useTranslate()
  const currentCluster = useSelector(selectCurrentCluster)
  const formik = useFormik({
    initialValues: {
      location: "",
      referenceDate: "",
      price: "",
      quantity: "",
      explanation: ""
    },
    validationSchema: CreateElementsSchema,
    onSubmit: async (
      { location, referenceDate, price, quantity, explanation },
      { resetForm }
    ) => {
      if (!currentCluster) return
      createElement({
        location,
        referenceDate,
        price: Number(price),
        quantity: Number(quantity),
        explanation,
        clusterId: currentCluster.id
      })
      resetForm()
      onClose()
    }
  })
  const actions = [
    {
      label: translate("go.back"),
      variant: "outlined",
      onClick: onClose
    },
    {
      label: translate("save"),
      variant: "contained",
      formId: "create-element-form",
      type: "submit"
    }
  ]

  const onChange = (e: React.ChangeEvent<any>, type = "text") => {
    e.persist()
    const re = /^[0-9\b]+$/
    if (type !== "number" || e.target.value === "") formik.handleChange(e)
    if (re.test(e.target.value)) formik.handleChange(e)
  }

  return (
    <Dialog
      open={open}
      title={translate("create.element.dialog.title")}
      onClose={onClose}
      actions={actions}
      maxWidth="lg"
      fullWidth
    >
      <form
        data-testid="create-element-dialog"
        id="create-element-form"
        onSubmit={formik.handleSubmit}
      >
        <Grid container rowSpacing={3} columnSpacing={5} pt={2}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              size="small"
              id="location"
              name="location"
              label={translate("elements.location")}
              value={formik.values.location}
              onChange={onChange}
              error={formik.touched.location && Boolean(formik.errors.location)}
              helperText={formik.touched.location && formik.errors.location}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              size="small"
              id="quantity"
              name="quantity"
              onChange={e => onChange(e, "number")}
              label={currentCluster?.meta.unit}
              value={formik.values.quantity}
              error={formik.touched.quantity && Boolean(formik.errors.quantity)}
              helperText={formik.touched.quantity && formik.errors.quantity}
            />
          </Grid>
          <Grid item xs={6}>
            <Box mb={3}>
              <TextField
                size="small"
                fullWidth
                id="price"
                name="price"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">Euro</InputAdornment>
                  )
                }}
                label={translate("elements.price")}
                value={formik.values.price}
                onChange={e => onChange(e, "number")}
                error={formik.touched.price && Boolean(formik.errors.price)}
                helperText={formik.touched.price && formik.errors.price}
              />
            </Box>
            <TextField
              fullWidth
              size="small"
              id="referenceDate"
              type="date"
              name="referenceDate"
              label={translate("elements.referenceDate")}
              InputLabelProps={{ shrink: true }}
              value={formik.values.referenceDate}
              onChange={onChange}
              error={
                formik.touched.referenceDate &&
                Boolean(formik.errors.referenceDate)
              }
              helperText={
                formik.touched.referenceDate && formik.errors.referenceDate
              }
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              multiline
              minRows={3}
              id="explanation"
              inputProps={{ maxLength: 420 }}
              name="explanation"
              label={translate("elements.explanation")}
              value={formik.values.explanation}
              onChange={onChange}
              error={
                formik.touched.explanation && Boolean(formik.errors.explanation)
              }
              helperText={
                formik.touched.explanation && formik.errors.explanation
              }
            />
          </Grid>
        </Grid>
      </form>
    </Dialog>
  )
}

export default CreateElementDialog

import { styled, Container } from "@mui/material"

export const StyledContainer = styled(Container)({
  display: "flex",
  flexDirection: "column",
  width: "100%"
})

export const StyledDataGridContainer = styled("div")({
  width: "100%",
  height: "500px",
  marginBottom: "30px"
})

export const StyledGraphContainer = styled("div")({
  width: "100%",
  height: "500px"
})

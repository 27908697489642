import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"
import { selectExpandedTreeNodes } from "app/redux/selectors/clustersOverviewSelectors"
import { useTranslate } from "hooks/translate"
import { useSelector } from "react-redux"
import { ClusterTreeEntity } from "types"
import ClusterTreeItem from "../ClusterTreeItem"
import { StyledTreeView } from "./style"

type ClusterTreeViewProps = {
  cluster: ClusterTreeEntity
}
const ClusterTreeView = ({ cluster }: ClusterTreeViewProps) => {
  const expanded = useSelector(selectExpandedTreeNodes)
  const { translate } = useTranslate()

  const renderTree = (nodes: ClusterTreeEntity, depth: number) => (
    <ClusterTreeItem
      clusterType={nodes.clusterType}
      key={nodes.id}
      clusterId={nodes.id}
      label={translate(nodes.name)}
      depth={depth}
    >
      {Array.isArray(nodes.children)
        ? nodes.children.map(node => renderTree(node, depth + 1))
        : null}
    </ClusterTreeItem>
  )

  return (
    <StyledTreeView
      key={cluster.id}
      aria-label="rich object"
      defaultCollapseIcon={<KeyboardArrowDownIcon />}
      defaultExpandIcon={<KeyboardArrowUpIcon />}
      expanded={expanded}
    >
      {renderTree(cluster, 1)}
    </StyledTreeView>
  )
}

export default ClusterTreeView

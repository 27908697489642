import { Typography } from "@mui/material"
import BackButton from "app/components/BackButton"
import CostEstimationFields from "app/components/CostEstimationFields"
import CreateElement from "app/components/CreateElement"
import ElementsGrid from "app/components/ElementsGrid"
import Graph from "app/components/Graph"
import PageHeader from "app/components/PageHeader"
import { setCurrentClusterId } from "app/redux/slices/clusterDetailSlice"
import { useTranslate } from "hooks/translate"
import { useDispatch } from "react-redux"
import { ClusterEntity } from "types"
import {
  StyledContainer,
  StyledDataGridContainer,
  StyledGraphContainer
} from "./style"

type Props = {
  cluster: ClusterEntity
}
export default function ClusterDetail({ cluster }: Props) {
  const dispatch = useDispatch()
  const { translate } = useTranslate()
  dispatch(setCurrentClusterId(cluster.id))

  return (
    <>
      <PageHeader
        leftNode={<BackButton to={"/"} text={translate("back.to.overview")} />}
        centerNode={
          <Typography variant="h5">{translate(cluster.name)}</Typography>
        }
      />
      <StyledContainer>
        <StyledGraphContainer>
          <CostEstimationFields />
          <Graph />
        </StyledGraphContainer>
        <StyledDataGridContainer>
          <ElementsGrid elements={cluster.elements} />
        </StyledDataGridContainer>
      </StyledContainer>
      <CreateElement />
    </>
  )
}

import { styled, Grid } from "@mui/material"

export const StyledGrid = styled(Grid)(() => ({
  height: "100%",
  width: "100%"
}))

export const StyledBox = styled(Grid)(() => ({
  height: "100%",
  width: "100%"
}))

import { AppBar, IconButton, Menu, MenuItem, Toolbar } from "@mui/material"
import ColoredAvatar from "app/components/ColoredAvatar"
import { Offset, StyledBox, Styledtypography } from "./style"
import { ENV } from "config"
import { selectCurrentUser } from "app/redux/selectors/currentUserSelector"
import { useAppSelector } from "hooks/redux"
import { getFullName } from "app/components/WithCurrentUser/utils"
import { useState } from "react"
import { useAuth0 } from "@auth0/auth0-react"
import { useTranslate } from "hooks/translate"

const Header = () => {
  const currentUser = useAppSelector(selectCurrentUser)
  const { translate } = useTranslate()
  const fullName = getFullName(currentUser)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const { logout } = useAuth0()
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLogout = () => {
    logout({ returnTo: window.location.origin })
  }

  return (
    <StyledBox>
      <AppBar position="fixed">
        <Toolbar>
          <Styledtypography variant="h6">{ENV.APP_NAME}</Styledtypography>
          <IconButton onClick={handleClick}>
            <ColoredAvatar name={fullName} />
          </IconButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button"
            }}
          >
            <MenuItem onClick={handleLogout}>{translate("logout")}</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <Offset />
    </StyledBox>
  )
}

export default Header

import { createSlice, PayloadAction } from "@reduxjs/toolkit"

type GraphState = {
  domainValues: {
    x: number
    y: number
  }
  defaultDomainValues: {
    x: number
    y: number
  }
  estimatedValues: {
    quantity: number | ""
    currentCost: number | ""
    showEstimation: boolean
  }
}

const initialState: GraphState = {
  domainValues: {
    x: 0,
    y: 0
  },
  defaultDomainValues: {
    x: 0,
    y: 0
  },
  estimatedValues: {
    quantity: "",
    currentCost: "",
    showEstimation: false
  }
}

export const graphSlice = createSlice({
  name: "graphSpecs",
  initialState,
  reducers: {
    setDefaultGraphDomain: (
      state,
      actions: PayloadAction<GraphState["defaultDomainValues"]>
    ) => {
      return {
        ...state,
        defaultDomainValues: actions.payload
      }
    },

    setGraphDomain: (
      state,
      actions: PayloadAction<GraphState["domainValues"]>
    ) => {
      return {
        ...state,
        domainValues: actions.payload
      }
    },
    setEstimatedValue: (
      state,
      actions: PayloadAction<GraphState["estimatedValues"]>
    ) => {
      return {
        ...state,
        estimatedValues: actions.payload
      }
    }
  }
})

export const { setGraphDomain, setDefaultGraphDomain, setEstimatedValue } =
  graphSlice.actions
export default graphSlice.reducer

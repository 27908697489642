import { Container, Typography } from "@mui/material"
import ClusterTreeView from "app/components/ClusterTree/ClusterTreeView"
import PageHeader from "app/components/PageHeader"
import { useTranslate } from "hooks/translate"
import { ClusterTreeEntity } from "types"

type Props = {
  clusters: ClusterTreeEntity[]
}
export default function ClustersOverview({ clusters }: Props) {
  const { translate } = useTranslate()

  return (
    <>
      <PageHeader
        centerNode={
          <Typography variant="h5">
            {translate("overview.element-clusters")}
          </Typography>
        }
      />
      <Container>
        {clusters.map(cluster => ClusterTreeView({ cluster }))}
      </Container>
    </>
  )
}

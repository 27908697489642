import { createDraftSafeSelector } from "@reduxjs/toolkit"
import { clusterEndpoints } from "app/api/bouwdataApi"
import { RootState } from "app/redux/store"

const selectRootState = (state: RootState) => state
export const selectClusterDetail = (state: RootState) => state.clusterDetail

export const selectCurrentCluster = createDraftSafeSelector(
  selectRootState,
  state =>
    clusterEndpoints.endpoints.getCluster.select(
      state.clusterDetail.currentClusterId
    )(state).data
)

export const selectClusterElements = createDraftSafeSelector(
  selectCurrentCluster,
  state => state?.elements
)

export const selectVisibleElements = createDraftSafeSelector(
  selectRootState,
  state => {
    const elements = selectClusterElements(state)
    if (!elements) return []

    const { visibleElementIds } = selectClusterDetail(state)
    if (!visibleElementIds) return []

    return elements.filter(element => visibleElementIds.includes(element.id))
  }
)

export const selectHiddenElements = createDraftSafeSelector(
  selectRootState,
  state => {
    const elements = selectClusterElements(state)
    if (!elements) return []

    const { visibleElementIds } = selectClusterDetail(state)
    if (!visibleElementIds) return elements

    return elements.filter(element => !visibleElementIds.includes(element.id))
  }
)

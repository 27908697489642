/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ClusterMetaEntity } from './ClusterMetaEntity';
import type { ElementEntity } from './ElementEntity';

export type ClusterEntity = {
    id: string;
    parentId: any;
    name: string;
    meta: ClusterMetaEntity;
    elements: Array<ElementEntity>;
    clusterType: ClusterEntity.clusterType;
    createdAt: string;
    updatedAt: string;
};

export namespace ClusterEntity {

    export enum clusterType {
        FOLDER_CLUSTER = 'FOLDER_CLUSTER',
        ELEMENT_CLUSTER = 'ELEMENT_CLUSTER',
    }


}

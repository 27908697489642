import { translations } from "app/i18n/config"
import { selectCurrentUser } from "app/redux/selectors/currentUserSelector"
import { useAppSelector } from "hooks/redux"
import { IntlProvider } from "react-intl"
import { UserEntity } from "types"

type Props = {
  children: JSX.Element
}

const I18nProvider = ({ children }: Props) => {
  const currentUser = useAppSelector(selectCurrentUser)
  const language = (() => {
    if (!currentUser) return UserEntity.language.EN
    return currentUser.language
  })()
  return (
    <IntlProvider messages={translations[language]} locale={language}>
      {children}
    </IntlProvider>
  )
}

export default I18nProvider
